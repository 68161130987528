import React from 'react'
import styled from 'styled-components'
import ContainerFull from '../../../../components/ContainerFull'
import DistrictNav from '../../../../components/DistrictsNav'
import TitleTopic from '../../../../components/TitleTopic'

const StyledDistrictNav = styled(DistrictNav)`
  border: none;
  box-shadow: none;
  background-color: #fff;
`

const StyledTitleTopic = styled(TitleTopic)`
  margin-bottom: 0;
  border: none;
`

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
`

const WidgetDistrictNav = ({ margin, ...props }) => (
  <Wrapper margin={margin}>
    <ContainerFull id={'ContainerFull'} noGapEdge {...props}>
      <StyledTitleTopic title="Bezirke" />
      <StyledDistrictNav open />
    </ContainerFull>
  </Wrapper>
)

WidgetDistrictNav.defaultProps = {
  margin: '0',
}

export default WidgetDistrictNav
